<template>
  <!-- BEGIN: Content-->
  <div class="app-content content">
    <div class="content-overlay" />
    <div class="header-navbar-shadow" />
    <div class="content-wrapper">
      <div class="content-header row">
        <div class="content-header-left col-md-9 col-12 mb-2">
          <div class="row breadcrumbs-top profile__avatar">
            <div class="col-12">
              <h2 class="content-header-title mb-0">
                {{ title }}
              </h2>
            </div>
          </div>
        </div>
      </div>
      <div class="content-body content-body--senior-call">
        <div class="row">
          <div class="col-12">
            <CompleteTable
              :sorted-field="sortedField"
              :data="mails"
              :sort-mapping="sortMapping"
              :title="'mails'"
              :total="total"
              :filter-path="'mailLogger/filter'"
            >
              <template v-slot="{field, showInfoSheet}">
                <td
                  class="text-end"
                  @click="showInfoSheet(field, 'templates'), loadPreview(field)"
                >
                  <span
                    class="dis-ib"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title="Edit area and group"
                  ><a
                    class="btn btn-icon rounded-circle btn-flat-dark"
                  ><i data-feather="eye" /></a></span>
                </td>
              </template>
              <template #info-sheet-item="{item}">
                <div class="offcanvas-body offcanvas-body--view">
                  <div class="mb-1 offcanvas-body--view--title">
                    <p>Mail template<br>
                      <strong>{{ item.template.title }}</strong></p>
                  </div>
                  <hr>
                  <div class="mb-1">
                    <p>To<br>
                      <strong>{{ item.to }}</strong></p>
                  </div>
                  <div class="mb-1">
                    <p>Date<br>
                      <strong>{{ item.date }}</strong></p>
                  </div>
                  <hr>
                  <div
                    class="col-sm-12"
                    v-html="templateHtml"
                  />
                </div>
                <div class="offcanvas-footer mt-auto">
                  <a
                    title="Edit trace"
                    class="btn btn-primary mb-1 d-grid w-100"
                    @click="resend(item)"
                  >Re-send mail</a>
                </div>
              </template>
              <template #customfilters>
                <div class="mb-1">
                  <label
                    for="nameseniorcall"
                    class="form-label"
                  >Researcher</label>
                  <v-select
                    label="name"
                    :options="users"
                    :get-option-key="option => option.id"
                    @search="onSearch({ name: $event }, 'users/fetchFakeUsers')"
                    @input="saveFilter($event, 'user')"
                  />
                </div>
                <div class="mb-1">
                  <label
                    for="nameseniorcall"
                    class="form-label"
                  >Template</label>
                  <v-select
                    label="title"
                    :options="templates"
                    :get-option-key="option => option.id"
                    @search="onSearch({ name: $event }, 'mailTemplates/fetch')"
                    @input="saveFilter($event, 'mail_template_id')"
                  />
                </div>
                <div class="mb-1">
                  <label
                    for="nameuser"
                    class="form-label"
                  >To</label>
                  <input
                    type="text"
                    class="form-control"
                    name="namerole"
                    @change="saveFilter($event.target.value, 'to')"
                  >
                </div>
                <div class="mb-1">
                  <label
                    for="nameseniorcall"
                    class="form-label"
                  >Begin date</label>
                  <br>
                  <date-picker
                    v-model="filters['begin_date']"
                    format="D MMM Y"
                    value-type="format"
                    class="w-100"
                    @change="saveFilter($event, 'begin_date')"
                  />
                </div>
                <div class="mb-1">
                  <label
                    for="nameseniorcall"
                    class="form-label"
                  >End date</label>
                  <br>
                  <date-picker
                    v-model="filters['end_date']"
                    format="D MMM Y"
                    class="w-100"
                    value-type="format"
                    @change="saveFilter($event, 'end_date')"
                  />
                </div>
              </template>
            </CompleteTable>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- END: Content-->
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import DatePicker from 'vue2-datepicker'
import vSelect from 'vue-select'
import CompleteTable from '../../partials/components/CompleteTable.vue'
import 'vue2-datepicker/index.css'

export default {
  components: {
    CompleteTable,
    DatePicker,
    vSelect,
  },
  data() {
    return {
      sortedField: 'To',
      defaultFields: [
        {
          name: 'To',
          checked: true,
          order: 1,
        },
        {
          name: 'Mail template',
          checked: true,
          order: 2,
        },
        {
          name: 'Date',
          checked: true,
          order: 3,
        },
      ],
      sortMapping: {
        To: 'to',
        'Mail template': 'template.title',
        Date: 'date',
      },
      title: 'Mail logger',
    }
  },
  computed: {
    ...mapGetters({
      templates: 'mailTemplates/items',
      users: 'users/fakeUsers',
      fields: 'modals/tableSettingsFields',
      mails: 'mailLogger/items',
      total: 'mailLogger/total',
      filters: 'filters/filters',
      templateHtml: 'mailTemplates/basicTemplate',
    }),
  },
  async mounted() {
    this.$store.dispatch('mailTemplates/fetchBasicTemplate')
    this.$store.dispatch('mailTemplates/fetch')
    await this.$store.dispatch('modals/fetchUserFields', 'mail_logger')
    await this.$store.dispatch('mailLogger/filter', this.filters)
    if (!this.fields || this.fields.length === undefined) { // If user doesnt have default fields, load the true default
      await this.$store.dispatch('modals/fetchFields', {
        fields: this.defaultFields,
        table: 'mail_logger',
      })
    }
    setTimeout(() => {
      feather.replace({
        width: 14,
        height: 14,
      })
    }, 500)
  },
  methods: {
    async resend(item) {
      Vue.swal({
        title: 'Do you want to re-send this mail?',
        html: '',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Yes',
      }).then(async result => {
        if (result.isConfirmed) {
          const resp = await Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/mail-loggers/resend-emails`, {
            mailId: item.id,
          })

          if (resp.status == 200) {
            Vue.swal('Mail sent successfully', '', 'success')
          } else {
            Vue.swal('The mail couldnt be sent.', '', 'error')
          }
        }
      })
    },
    saveFilter(value, field) {
      if (field === 'mail_template_id') {
        value.name = value.title
      }
      this.$store.dispatch('filters/saveFilters', {
        value,
        field,
      })
    },
    async onSearch(search, store) {
      if (search.name !== '' && search.name.length > 2) {
        await this.$store.dispatch(store, search)
      }
    },
    loadPreview(field) {
      setTimeout(() => {
        let string = ''

        JSON.parse(field.content).forEach(item => {
          if (item.type === 'text-block') {
            string = `${string} <p class="sm-leading-32" style="font-size: 18px; margin: 0 0 24px; --text-opacity: 1; color: #263238; color: rgba(38, 50, 56, var(--text-opacity));">
                ${item.content}
            </p>`
          } else if (item.type === 'button') {
            string = `${string} <span style="padding-top:1rem; padding-bottom: 2rem;">
                <a href="${item.button_link}" class="btn btn-${item.button_color}">${item.button_text ?? 'Text button'}</a>
            </span>`
          } else if (item.type === 'image') {
            string = `${string} <div style="padding-top:1rem; padding-bottom: 2rem;">
                <img src="${item.image ? item.image.url : ''}" class="img-fluid" alt="">
            </div>`
          }
        })

        const mailContent = document.getElementById('mailContent')
        if (mailContent) {
          mailContent.innerHTML = string
        }

        const mailTitle = document.getElementById('mailTitle')
        if (mailTitle) {
          mailTitle.innerHTML = field.template.content_title
        }
      }, 500)
    },
  },
}
</script>
